import { createAsyncThunk } from '@reduxjs/toolkit';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { Connection, LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js';
import ShowToast from '../../../components/ShowToast/ShowToast';
import { PINX_MINT } from '../../../constants/constants';

interface FetchBalancesArgs {
  connection: Connection;
  publicKey: PublicKey;
}

interface FetchBalancesPayload {
  solBalance: number;
  pinxBalance: number;
}

export const fetchBalancesThunk = createAsyncThunk<FetchBalancesPayload, FetchBalancesArgs>(
  'wallet/fetchBalances',
  async ({ connection, publicKey }, { rejectWithValue }) => {
    try {
      // Fetch SOL balance
      const solBalance = await connection.getBalance(publicKey);
      const solBalanceInSol = solBalance / LAMPORTS_PER_SOL;

      // Fetch token balances
      const tokenAccounts = await connection.getParsedTokenAccountsByOwner(
        publicKey,
        { programId: TOKEN_PROGRAM_ID }
      );

      let pinxBalance = 0;
      tokenAccounts.value.forEach((accountInfo) => {
        const parsedAccountInfo = accountInfo.account.data.parsed;
        const mintAddress = parsedAccountInfo.info.mint;

        // Check if the token account is for the PINX token
        if (mintAddress === PINX_MINT) {
          pinxBalance = parsedAccountInfo.info.tokenAmount.uiAmount;
        }
      });

      // Return balances
      return { solBalance: solBalanceInSol, pinxBalance };
    } catch (error) {
      // Show error toast
      ShowToast({
        type: 'error',
        title: 'Balance Fetch Error',
        text: 'Failed to fetch balances. Please try again later.',
      });

      // Reject with error message
      return rejectWithValue((error as Error).message);
    }
  }
);
