import { configureStore } from '@reduxjs/toolkit';
import walletReducer from './slices/walletSlice';
import swapReducer from './slices/swapSlice'

// Configure the store
const store = configureStore({
  reducer: {
    wallet: walletReducer,
    swap: swapReducer, 
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
