import { createAsyncThunk } from '@reduxjs/toolkit';
import ShowToast from '../../../components/ShowToast/ShowToast';
import { JUPITER_API_URL, PINX_MINT, SOL_MINT } from '../../../constants/constants';
import { VersionedTransaction, Connection, PublicKey } from '@solana/web3.js';
import { fetchBalancesThunk } from './fetchBalancesThunk';

export const swapTokensThunk = createAsyncThunk(
  'swap/swapTokens',
  async (
    {
      wallet,
      connection,
      solAmount,
      pinxAmount,
      slippage,
      isFlipped,
    }: {
      wallet: any;
      connection: Connection;
      solAmount: string;
      pinxAmount: string;
      slippage: number;
      isFlipped: boolean;
    },
    { rejectWithValue, dispatch }
  ) => {
    if (!wallet.connected) {
      ShowToast({
        type: 'error',
        title: 'Wallet Not Connected!',
        text: 'Please connect your wallet before swapping.',
      });
      return rejectWithValue('Wallet not connected');
    }

    try {
      const SOL_DECIMALS = 4;
      const PINX_DECIMALS = 4;

      console.log('Parsing amounts...');
      const solAmountParsed = Math.floor(parseFloat(solAmount) * Math.pow(10, SOL_DECIMALS)) / Math.pow(10, SOL_DECIMALS);
      const pinxAmountParsed = Math.floor(parseFloat(pinxAmount) * Math.pow(10, PINX_DECIMALS)) / Math.pow(10, PINX_DECIMALS);
      console.log('Parsed SOL Amount:', solAmountParsed, 'Parsed PINX Amount:', pinxAmountParsed);

      const slippageBps = slippage * 100;
      console.log('Slippage BPS:', slippageBps);

      const inputMint = isFlipped ? PINX_MINT : SOL_MINT;
      const outputMint = isFlipped ? SOL_MINT : PINX_MINT;
      const amountLamports = isFlipped
        ? Math.floor(pinxAmountParsed * 1_000_000_000)
        : Math.floor(solAmountParsed * 1_000_000_000);
      console.log('Input Mint:', inputMint, 'Output Mint:', outputMint, 'Amount in Lamports:', amountLamports);

      const feeBps = 50; // 0.5% fee
      const referralAccountPubkey = new PublicKey('GY98kjWMhGxhh7Q3fd56VrdnVrwYBnnb3rjh9ksDxd27');
      const referralProgramId = new PublicKey('REFER4ZgmyYx9c6He5XfaTMiGfdLwRnkV4RPp9t9iF3');
      const mint = isFlipped ? outputMint : inputMint;

      console.log('Referral Account PublicKey:', referralAccountPubkey.toBase58());
      console.log('Referral Program ID:', referralProgramId.toBase58());

      // Find the fee account
      console.log('Finding fee account...');
      const [feeAccount] = await PublicKey.findProgramAddressSync(
        [
          Buffer.from('referral_ata'),
          referralAccountPubkey.toBuffer(),
          new PublicKey(mint).toBuffer(),
        ],
        referralProgramId
      );

      console.log('Fee Account:', feeAccount.toBase58());

      const quoteUrl = `${JUPITER_API_URL}/quote?inputMint=${inputMint}&outputMint=${outputMint}&amount=${amountLamports}&slippageBps=${slippageBps}&platformFeeBps=${feeBps}&feeAccount=${feeAccount.toBase58()}`;

      console.log('Quote URL:', quoteUrl);
      console.log('Fetching quote...');
      const quoteResponse = await fetch(quoteUrl);
      if (!quoteResponse.ok) {
        ShowToast({
          type: 'error',
          title: 'Swap Error',
          text: `Quote API failed with status: ${quoteResponse.status}`,
        });
        console.error('Quote API failed with status:', quoteResponse.status);
        return rejectWithValue(`Quote API failed with status: ${quoteResponse.status}`);
      }

      const quoteData = await quoteResponse.json();
      console.log('Quote Response:', quoteData);

      if (!quoteData || !quoteData.routePlan || quoteData.routePlan.length === 0) {
        ShowToast({
          type: 'error',
          title: 'Swap Error',
          text: 'No routes found for swap.',
        });
        console.error('No routes found for swap. Quote Response:', quoteData);
        return rejectWithValue('No routes found for swap');
      }

      const bestRoute = quoteData;
      console.log('Best Route Selected:', bestRoute);

      console.log('Fetching swap transaction...');
      const swapResponse = await fetch(`${JUPITER_API_URL}/swap`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          quoteResponse: bestRoute,
          userPublicKey: wallet.publicKey.toBase58(),
          wrapAndUnwrapSol: true,
          feeAccount: feeAccount.toBase58(),
        }),
      });

      if (!swapResponse.ok) {
        ShowToast({
          type: 'error',
          title: 'Swap Error',
          text: `Swap API failed with status: ${swapResponse.status}`,
        });
        console.error('Swap API failed with status:', swapResponse.status);
        return rejectWithValue(`Swap API failed with status: ${swapResponse.status}`);
      }

      const swapResponseData = await swapResponse.json();
      console.log('Swap Response:', swapResponseData);

      const { swapTransaction } = swapResponseData;
      if (!swapTransaction) {
        ShowToast({
          type: 'error',
          title: 'Swap Error',
          text: 'No swap transaction found in response.',
        });
        console.error('No swap transaction found in response:', swapResponseData);
        return rejectWithValue('No swap transaction found in response');
      }

      console.log('Deserializing transaction...');
      const transactionBuffer = Buffer.from(swapTransaction, 'base64');
      const uint8ArrayTransaction = new Uint8Array(transactionBuffer);
      const transaction = VersionedTransaction.deserialize(uint8ArrayTransaction);

      console.log('Sending transaction...');
      const signature = await wallet.sendTransaction(transaction, connection);

      console.log('Transaction Signature:', signature);

      console.log('Confirming transaction...');
      const latestBlockHash = await connection.getLatestBlockhash();
      await connection.confirmTransaction({
        blockhash: latestBlockHash.blockhash,
        lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
        signature: signature,
      });

      ShowToast({
        type: 'success',
        title: 'Swap Successful!',
        text: (
          <a href={`https://solscan.io/tx/${signature}`} target="_blank" rel="noopener noreferrer">
            View on Solscan
          </a>
        ),
      });

      console.log('Fetching updated balances...');
      await dispatch(fetchBalancesThunk({ connection, publicKey: wallet.publicKey }));

      console.log('Swap completed successfully.');
      return signature;
    } catch (error: any) {
      console.error('Error during swap:', error);
      ShowToast({
        type: 'error',
        title: 'Swap Failed',
        text: `Error: ${error.message}`,
      });

      // Fetch balances even if swap fails
      console.log('Fetching balances after failure...');
      await dispatch(fetchBalancesThunk({ connection, publicKey: wallet.publicKey }));

      return rejectWithValue(error.message);
    }
  }
);