import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { swapTokensThunk } from '../thunks/swapThunk';

interface SwapState {
  isLoading: boolean;
  isSuccess: boolean;
  error: string | null;
}

const initialState: SwapState = {
  isLoading: false,
  isSuccess: false,
  error: null,
};

const swapSlice = createSlice({
  name: 'swap',
  initialState,
  reducers: {
    resetSwapState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.error = null;
    },
    clearSwapError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(swapTokensThunk.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.error = null;
      })
      .addCase(swapTokensThunk.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(swapTokensThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.error =
          typeof action.payload === 'string'
            ? action.payload
            : action.error.message || 'An error occurred';
      });
  },
});

// Export the actions so they can be used elsewhere in the app
export const { resetSwapState, clearSwapError } = swapSlice.actions;

// Export the reducer to be used in the store configuration
export default swapSlice.reducer;


