import React, { createContext, useContext, useState, useEffect, useRef, ReactNode } from 'react';
import { useAssets } from './AssetsProvider';
import { Asset } from '../types';

interface SymbolsContextType {
  symbols: string[];
  stockData: Record<string, any>;
  isWebsocketConnected: boolean;
  assets: Asset[];
}

interface SymbolsProviderProps {
  children: ReactNode;
}

const SymbolsContext = createContext<SymbolsContextType | undefined>(undefined);

const WEBSOCKET_URL = process.env.NEXT_PUBLIC_WEBSOCKET_URL;

export const SymbolsProvider: React.FC<SymbolsProviderProps> = ({ children }) => {
  const { assets } = useAssets();
  const [symbols, setSymbols] = useState<string[]>([]);
  const [stockData, setStockData] = useState<Record<string, any>>({});
  const [isWebsocketConnected, setIsWebsocketConnected] = useState<boolean>(false);
  const ws = useRef<WebSocket | null>(null);
  const reconnectTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleWebSocketMessage = (event: MessageEvent) => {
    const newPriceData = JSON.parse(event.data);

    if (newPriceData.message === 'Forbidden') {
      console.error('WebSocket subscription was forbidden:', newPriceData);
      return;
    }

    setStockData((prevData) => {
      const updatedData = { ...prevData };
      Object.keys(newPriceData).forEach((symbol) => {
        updatedData[symbol] = newPriceData[symbol];
      });
      return updatedData;
    });
  };

  const initializeWebSocket = () => {
    if (!ws.current && symbols.length > 0) {
      ws.current = new WebSocket(WEBSOCKET_URL);

      ws.current.onopen = () => {
        setIsWebsocketConnected(true);
        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
          ws.current.send(JSON.stringify({ action: 'subscribe', symbols }));
        }
      };

      ws.current.onmessage = handleWebSocketMessage;

      ws.current.onclose = (event) => {
        setIsWebsocketConnected(false);
        ws.current = null;

        if (reconnectTimeout.current) clearTimeout(reconnectTimeout.current);

        reconnectTimeout.current = setTimeout(() => initializeWebSocket(), 30000);
      };

      ws.current.onerror = () => {
        setIsWebsocketConnected(false);
      };
    }
  };

  useEffect(() => {
    if (assets.length > 0) {
      setSymbols(assets.map(asset => asset.symbol));
    }
  }, [assets]);

  useEffect(() => {
    if (symbols.length > 0) {
      initializeWebSocket();
    }

    return () => {
      if (ws.current) {
        ws.current.close();
        ws.current = null;
      }
      if (reconnectTimeout.current) clearTimeout(reconnectTimeout.current);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [symbols]);

  return (
    <SymbolsContext.Provider value={{ symbols, stockData, isWebsocketConnected, assets }}>
      {children}
    </SymbolsContext.Provider>
  );
};

export default SymbolsContext;
