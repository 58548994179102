import React, { createContext, useContext, useEffect, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetBalances } from '../core/redux/slices/walletSlice';
import { fetchBalancesThunk } from '../core/redux/thunks/fetchBalancesThunk';
import { RootState } from '../core/redux/store';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';

// Define the type for the wallet state
interface WalletState {
  solBalance: number;
  pinxBalance: number;
  loading: boolean;
  error: string | null;
}

// Define the type for the context value
interface WalletContextValue {
  walletState: WalletState;
  resetWalletBalances: () => void;
}

// Create the context with a default value
const WalletContext = createContext<WalletContextValue | undefined>(undefined);

// Custom hook to use WalletContext easily
export const useWalletContext = () => {
  const context = useContext(WalletContext);
  if (context === undefined) {
    console.warn('useWalletContext must be used within a WalletProvider');
    return {} as WalletContextValue; // Return a default value to avoid runtime errors
  }
  return context;
};

interface WalletProviderProps {
  children: ReactNode;
}

export const WalletProvider: React.FC<WalletProviderProps> = ({ children }) => {
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const wallet = useWallet();
  const { connection } = useConnection();
  const walletState = useSelector((state: RootState) => state.wallet);

  useEffect(() => {
    if (wallet.connected && wallet.publicKey) {
      // Fetch balances when the wallet is connected
      dispatch(fetchBalancesThunk({ connection, publicKey: wallet.publicKey }));
    } else {
      // Reset balances if the connection is lost or the wallet is disconnected
      dispatch(resetBalances());
    }
  }, [wallet.connected, wallet.publicKey, dispatch, connection]);

  const resetWalletBalances = () => {
    dispatch(resetBalances());
  };

  const value = {
    walletState,
    resetWalletBalances,
  };

  return (
    <WalletContext.Provider value={value}>
      {children}
    </WalletContext.Provider>
  );
};
