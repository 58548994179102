export const PINX_MINT = '6m32vKEf22fZVV2NC69WNabDAx7WDN4ZSgGWjUJuRQEV';
export const SOL_MINT = 'So11111111111111111111111111111111111111112'; 
export const PLATFORM_FEE_ACCOUNT = '9D2UpqzX2mtBLRknuXRMYkt698okENjnnCdkVi9QSXJB'; 
export const PLATFORM_FEE_BPS = 0; 
export const JUPITER_API_URL = process.env.NEXT_PUBLIC_JUPITER_API_URL;
export const MINIMUM_SOL_AMOUNT = 0.0000000001;
export const MAXIMUM_SOL_AMOUNT = 200000;
export const MAXIMUM_PINX_AMOUNT = 100000000;
export const MINIMUM_PINX_AMOUNT = 0.0000000001;
export const MAX_DIGITS_LEFT = 15;
export const MAX_DECIMALS = 10;
const FEE_RECIPIENT = '9D2UpqzX2mtBLRknuXRMYkt698okENjnnCdkVi9QSXJB';
const FEE_PERCENTAGE = 0.001; 
