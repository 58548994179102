import { createSlice } from '@reduxjs/toolkit';
import { fetchBalancesThunk } from '../thunks/fetchBalancesThunk';
import { swapTokensThunk } from '../thunks/swapThunk';

const walletSlice = createSlice({
  name: 'wallet',
  initialState: {
    solBalance: 0,       // The SOL balance of the connected wallet
    pinxBalance: 0,      // The PINX balance of the connected wallet
    loading: false,      // Loading state for balance fetching
    swapLoading: false,  // Loading state for the swap process
    error: null,         // Error state if balance fetching fails
    swapError: null,     // Error state if the swap process fails
  },
  reducers: {
    resetBalances: (state) => {
      state.solBalance = 0;
      state.pinxBalance = 0;
    },
    clearError: (state) => {
      state.error = null;
      state.swapError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBalancesThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBalancesThunk.fulfilled, (state, action) => {
        state.solBalance = action.payload.solBalance;
        state.pinxBalance = action.payload.pinxBalance;
        state.loading = false;
      })
      .addCase(fetchBalancesThunk.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(swapTokensThunk.pending, (state) => {
        state.swapLoading = true; // Set swap loading state when the thunk is pending
        state.swapError = null;   // Reset swap error state
      })
      .addCase(swapTokensThunk.fulfilled, (state) => {
        state.swapLoading = false; // Set swap loading state to false
      })
      .addCase(swapTokensThunk.rejected, (state, action) => {
        state.swapError = action.payload; // Set error message when the swap fails
        state.swapLoading = false; // Set swap loading state to false
      });
  },
});

export const { resetBalances, clearError } = walletSlice.actions;
export default walletSlice.reducer;
