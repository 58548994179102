import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Asset } from '../types';

interface AssetsContextType {
  assets: Asset[];
  toggleFavorite: (index: number, event: React.MouseEvent<SVGElement, MouseEvent>) => void;
}

interface AssetsProviderProps {
  children: ReactNode;
}

const AssetsContext = createContext<AssetsContextType | undefined>(undefined);

const allAssets: Asset[] = [
  { title: 'Solana', symbol: 'SOL', image: '', latestPrice: '', dailyPercentageChange: '', high_24h: '', low_24h: '', volume: '', isFavorite: false },
  { title: 'USD Coin', symbol: 'USDC', image: '', latestPrice: '', dailyPercentageChange: '', high_24h: '', low_24h: '', volume: '', isFavorite: false },
  { title: 'PINX Global Markets', symbol: 'PINX', image: '', latestPrice: '', dailyPercentageChange: '', high_24h: '', low_24h: '', volume: '', isFavorite: false },
  { title: 'Toyota', symbol: 'TM', image: '', latestPrice: '', dailyPercentageChange: '', high_24h: '', low_24h: '', volume: '', isFavorite: false },
  { title: 'Nike', symbol: 'NKE', image: '', latestPrice: '', dailyPercentageChange: '', high_24h: '', low_24h: '', volume: '', isFavorite: false },
  { title: 'Honda', symbol: 'HMC', image: '', latestPrice: '', dailyPercentageChange: '', high_24h: '', low_24h: '', volume: '', isFavorite: false },
  { title: 'Intel', symbol: 'INTC', image: '', latestPrice: '', dailyPercentageChange: '', high_24h: '', low_24h: '', volume: '', isFavorite: false },
  { title: 'Ford', symbol: 'F', image: '', latestPrice: '', dailyPercentageChange: '', high_24h: '', low_24h: '', volume: '', isFavorite: false },
  { title: 'Amazon', symbol: 'AMZN', image: '', latestPrice: '', dailyPercentageChange: '', high_24h: '', low_24h: '', volume: '', isFavorite: false },
  { title: 'Google', symbol: 'GOOGL', image: '', latestPrice: '', dailyPercentageChange: '', high_24h: '', low_24h: '', volume: '', isFavorite: false },
  { title: 'Tesla', symbol: 'TSLA', image: '', latestPrice: '', dailyPercentageChange: '', high_24h: '', low_24h: '', volume: '', isFavorite: false },
];

export const AssetsProvider: React.FC<AssetsProviderProps> = ({ children }) => {
  const [assets, setAssets] = useState<Asset[]>(allAssets);

  const toggleFavorite = (index: number, event: React.MouseEvent<SVGElement, MouseEvent>) => {
    event.stopPropagation();
    const updatedAssets = assets.map((item, idx) =>
      idx === index ? { ...item, isFavorite: !item.isFavorite } : item
    );
    setAssets(updatedAssets);
    const favoriteSymbols = updatedAssets.filter((item) => item.isFavorite).map((item) => item.symbol);
    localStorage.setItem('favoriteAssets', JSON.stringify(favoriteSymbols));
  };

  return (
    <AssetsContext.Provider value={{ assets, toggleFavorite }}>
      {children}
    </AssetsContext.Provider>
  );
};

export const useAssets = () => {
  const context = useContext(AssetsContext);
  if (!context) {
    throw new Error('useAssets must be used within an AssetsProvider');
  }
  return context;
};
